import React from "react";
import PropTypes from "prop-types";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import PatientQuestionnaireResults from "./patient-questionnaire-results";
import PureModal from "components/ui/modal/pure-modal";
import EditLink from "components/ui/edit-link";
import { Link } from "react-router-dom";
import moment from "moment";

const PatientQuestionnaires = ({ questionnaires }) => {
  const { rows, query, queryDispatcher, count } = useLocalQuery(
    questionnaires,
    {
      sort: { by: "questionnaire_date", direction: "asc" },
      filters: {},
      pagination: { page: 1, per: 100 }
    }
  );
  return (
    <Table
      containerClass="query-table"
      rowKey="questionnaire_assignment_id"
      isFetching={false}
      columns={[
        {
          header: "Patient",
          field: "patient_name"
        },
        {
          header: "Company",
          field: "company"
        },
        {
          header: "Title",
          field: "title"
        },
        {
          header: "Assigned Date",
          field: "questionnaire_date"
        },
        {
          header: "Reminder Sent",
          field: "reminder_sent"
        },
        {
          header: "Ignored",
          field: "ignored"
        },
        {
          header: "Response Date",
          field: "responded_date"
        },
        {
          field: "",
          header: " "
        }
      ]}
      rows={rows}
      query={query}
      count={count}
      queryDispatcher={queryDispatcher}
      TableRow={({
        patient_name,
        patient_id,
        title,
        questionnaire_date,
        questionnaire_assignment_id,
        responded_date,
        company,
        ignored,
        reminderSent
      }) => (
        <tr>
          <td>
            <Link to={`/patients/${patient_id}`}>{patient_name}</Link>
          </td>
          <td>{ignored ? company : ""}</td>
          <td>{title}</td>
          <td>{moment(questionnaire_date).format("L")}</td>
          <td>{reminderSent ? moment(reminderSent).format("L") : ""}</td>
          <td>{ignored ? "Yes" : "No"}</td>
          <td>{responded_date ? moment(responded_date).format("L") : ""}</td>
          <td>
            <span
              style={{
                display: "grid",
                gridTemplateColumns: "70px 70px",
                gridGap: 10
              }}
            >
              {responded_date && (
                <PureModal
                  renderTrigger={({ openModal }) => (
                    <EditLink
                      onClick={() => {
                        openModal();
                      }}
                      showIcon={false}
                      text="View"
                    />
                  )}
                  windowProps={{ large: true }}
                >
                  <PatientQuestionnaireResults
                    patient_id={patient_id}
                    questionnaire_assignment_id={questionnaire_assignment_id}
                  />
                </PureModal>
              )}
            </span>
          </td>
        </tr>
      )}
    />
  );
};

PatientQuestionnaires.propTypes = {
  patientId: PropTypes.string.isRequired,
  Questionnaire: PropTypes.arrayOf(PropTypes.object),
  getPatientQuestionnaire: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default PatientQuestionnaires;
