import React, { useState } from "react";
import PatientQuestionnaires from "components/dd_newModules/subModule/PatientQuestionnaire/patient-questionnaires";
import PatientsSearch from "./patients-search";
import { Spinner } from "components/ui";

const PatientQuestionnaireSearch = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  return (
    <div
      style={{
        marginBottom: "15px",
        display: "flex",
        flex: 1,
        flexDirection: "column"
      }}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid #DEEAEE",
          padding: "20px"
        }}
      >
        <PatientsSearch setSearchResults={setSearchResults} setIsFetching={setIsFetching} />
      </div>
      <div style={{ padding: 20 }}>
        {isFetching && <Spinner/>}
        {!isFetching && searchResults &&<PatientQuestionnaires questionnaires={searchResults} />}        
      </div>
    </div>
  );
};

export default PatientQuestionnaireSearch;
