import React, { useEffect, useState } from "react";
import { useFetch } from "hooks";
import { path } from "ramda";
import { put } from "utils/api";
import { useDispatch } from "react-redux";
import * as msg from "actions/message";
import { Spinner } from "components/ui";

const PatientsSearch = ({ setSearchResults, setIsFetching }) => {
  const dispatch = useDispatch();

  const initialValues = {
    formName: "",
    patientName: "",
    patientEmail: "",
    patientDOB: ""
  };
  const [filters, setFilters] = useState(initialValues);
  const [enablePatientSearh, setEnablePatientSearch] = useState(true);
  const [enableFormSearh, setEnableFormSearch] = useState(true);

  const { fetchData, isFetching } = useFetch({
    apiFn: body => put(`dme_portal/patients/questionnaires`, body),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: () => {
      dispatch(msg.errorMessage("No patients found"));
    },
    onSuccess: ({ questionnaires }) => {
      setSearchResults(questionnaires);
    }
  });

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isFetching]);

  return (
    <>
      <div className="patient-selector">
        <>
          <form>
            <h4>Search by Patient</h4>

            <label>Patient Name</label>
            <input
              type="text"
              value={filters.patientName}
              name="name"
              placeholder="Enter Patient Name"
              onChange={e => {
                const val = e.target.value;
                const enableFormSearch =
                  val == "" &&
                  filters.patientEmail == "" &&
                  filters.patientDOB == "";
                setEnableFormSearch(enableFormSearch);
                setFilters({ ...filters, patientName: val });
              }}
              disabled={!enablePatientSearh}
            />
            <label>Patient Email</label>
            <input
              type="text"
              value={filters.patientEmail}
              name="email"
              placeholder="Enter Patient Email"
              onChange={e => {
                const val = e.target.value;
                const enableFormSearch =
                  val == "" &&
                  filters.patientName == "" &&
                  filters.patientDOB == "";
                setEnableFormSearch(enableFormSearch);
                setFilters({ ...filters, patientEmail: val });
              }}
              disabled={!enablePatientSearh}
            />
            <label>Patient Date of Birth</label>
            <input
              type="date"
              value={filters.patientDOB}
              onChange={e => {
                const val = e.target.value;
                const enableFormSearch =
                  val == "" &&
                  filters.patientEmail == "" &&
                  filters.patientName == "";
                setEnableFormSearch(enableFormSearch);
                setFilters({ ...filters, patientDOB: val });
              }}
              disabled={!enablePatientSearh}
            />
          </form>
        </>
        <>
          <form>
            <h4>Search by Form</h4>
            <label>Form Name</label>
            <input
              type="text"
              value={filters.formName}
              name="Form name"
              placeholder="Enter Form Name"
              onChange={e => {
                const enablePTSearch = e.target.value == "";
                setEnablePatientSearch(enablePTSearch);
                setFilters({ ...filters, formName: e.target.value });
              }}
              disabled={!enableFormSearh}
            />
          </form>
        </>
      </div>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <button
          type="button"
          className="button warning"
          style={{ marginRight: "20px" }}
          onClick={() => {
            setFilters(initialValues);
            setEnableFormSearch(true);
            setEnablePatientSearch(true);
          }}
        >
          Clear
        </button>
        <button
          type="submit"
          onClick={async () => {
            const body = {
              PatientName: filters.patientName,
              DateOfBirth: filters.patientDOB,
              Email: filters.patientEmail,
              FormName: filters.formName
            };
            await fetchData(body);
          }}
          disabled={isFetching}
        >
          Search {isFetching && <Spinner />}
        </button>
      </div>
    </>
  );
};

export default PatientsSearch;
